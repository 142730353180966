<template>
  <div>
    <!-- 结束作业 -->
    <Modal v-model="isFinish" v-if="isFinishStatus" width="1200" :title="$t('key1003920')" :styles="{ top: '100px' }">
      <!-- 无异常 -->
      <div v-if="endStatus">
        <div style="height:60px;paddingLeft:10px;marginBottom:20px;border:1px solid #ccc;background:#ffffcc;lineHeight:60px;font-size:16px;font-weight:bold;">
          {{ $t('key1003921').replace('{pickingGoodsNo}', pickingGoodsNo) }}
        </div>
        <template v-if="pageType === 'prepareGoods'">
          <Table  border max-height="138" :columns="countDataColumn" :data="countBhData"></Table>
          <div style="marginTop:20px;">
            {{ $t('key1003923').replace('{scannedTime}', scannedTime) }}
          </div>
          <Table border :columns="userDataBhColumn" :data="userBhData"></Table>
        </template>
        <template v-else>
          <Table  border max-height="138" :columns="countDataColumn" :data="countData"></Table>
          <div style="marginTop:20px;">
            {{ $t('key1003922').replace('{scannedCount}', scannedCount) }}，{{ $t('key1003923').replace('{scannedTime}', scannedTime) }}，{{ $t('key1003924').replace('{userScannedTime}', userScannedTime) }}
          </div>
          <Table border :columns="userDataColumn" :data="userData"></Table>
        </template>

      </div>
      <!-- 有异常 -->
      <div v-if="!endStatus">
        <div style="height:60px;paddingLeft:10px;marginBottom:20px;border:1px solid #ccc;background:#ffffcc;lineHeight:60px;font-size:16px;font-weight:bold;">
          {{ $t('key1003925').replace('{pickingGoodsNo}', pickingGoodsNo) }}：
        </div>
        <template v-if="pageType === 'prepareGoods'">
          <Table
            border
            :columns="printedPackageColumn"
            :data="printBhData"></Table>
        </template>
        <template v-else>
          <div v-if="printData.length > 0" style="height:320px;border:1px solid #ccc;background:#d6e9f2;marginTop:20px;">
            <Row>
              <Col span="15" style="marginLeft:10px;">
                <Row>
                  <Col span="20">
                    <span style="color:#ff0000;">{{ $t('key1003926') }}</span>
                  </Col>
                  <Col span="4">
                    <span style="cursor:pointer;" @click="importExcel(0)">{{ $t('key1003927') }}</span>
                  </Col>
                </Row>
                <Table border height="200" :columns="printDataColumn" :data="printData"></Table>
              </Col>
              <Col span="8" style="marginLeft:20px;">
                <span style="cursor:pointer;" @click="importExcel(1)">{{ $t('key1003927') }}</span>
                <Table border max-height="200" :columns="printGoodsDataColumn" :data="printGoodsData"></Table>
              </Col>
            </Row>
            <div style="marginTop:20px;marginLeft:10px;fontSize:14px;">
              <div>{{ $t('key1003928') }}</div>
              <div>{{ $t('key1003929') }}</div>
              <div>{{ $t('key1003930') }}</div>
            </div>
          </div>
          <div v-if="cancelData.length > 0" style="height:320px;border:1px solid #ccc;background:#d6e9f2;marginTop:20px;">
            <Row>
              <Col span="15" style="marginLeft:10px;">
                <Row>
                  <Col span="20">
                    <span style="color:#ff0000;">{{ $t('key1003931') }}</span>
                  </Col>
                  <Col span="4">
                    <span style="cursor:pointer;" @click="startImportExcel(0)">{{ $t('key1003927') }}</span>
                  </Col>
                </Row>
                <Table border max-height="200" :columns="cancelDataColumn" :data="cancelData"></Table>
              </Col>
              <Col span="8" style="marginLeft:20px;">
                <span style="cursor:pointer;" @click="startImportExcel(1)">{{ $t('key1003927') }}</span>
                <Table border max-height="200" :columns="cancelGoodsDataColumn" :data="cancelGoodsData"></Table>
              </Col>
            </Row>
            <div style="marginTop:20px;marginLeft:10px;fontSize:14px;">
              <div>{{ $t('key1003932') }}</div>
              <div>{{ $t('key1003933') }}</div>
              <div>{{ $t('key1003934') }}</div>
            </div>
          </div>
          <div v-if="cancelPrintData.length > 0" style="height:320px;border:1px solid #ccc;background:#d6e9f2;marginTop:20px;">
            <Row>
              <Col span="15" style="marginLeft:10px;">
                <Row>
                  <Col span="20">
                    <span style="color:#ff0000;">{{ $t('key1003935') }}</span>
                  </Col>
                  <Col span="4">
                    <span style="cursor:pointer;" @click="endImportExcel(0)">{{ $t('key1003927') }}</span>
                  </Col>
                </Row>
                <Table border height="200" :columns="cancelPrintColumn" :data="cancelPrintData"></Table>
              </Col>
              <Col span="8" style="marginLeft:20px;">
                <span style="cursor:pointer;" @click="endImportExcel(1)">{{ $t('key1003927') }}</span>
                <Table border height="200" :columns="cancelPrintGoodsColumn" :data="cancelPrintGoodsData"></Table>
              </Col>
            </Row>
            <div style="marginTop:20px;marginLeft:10px;fontSize:14px;">
              <div>{{ $t('key1003936') }}</div>
            </div>
          </div>
          <!--打印未拣货/打印出库单明细-->
          <div>
            <p class="red title">{{ $t('key1006137') }}
              <Button type="primary" class="ml10" @click="PrintDistributionList">{{ $t('key1004363') }} </Button>
            </p>
            <Table border :columns="columns" :data="data"></Table>
          </div>
        </template>

      </div>
      <template #footer>
        <Button v-if="endStatus" type="primary" @click="finishPackWork(0)">{{ $t('key1003937') }} </Button>
        <Button v-if="!endStatus" type="error" @click="finishPackWork(1)">{{ $t('key1003219') }} </Button>
        <Button v-if="!endStatus" type="primary" @click="backPacking">{{ $t('key1003938') }} </Button>
      </template>
    </Modal>

  </div>
</template>

<script>
import { aliasb039bd6e4486415fba0a6b42f8cd95d1 } from '@/customFolder/customVueAlias.js';

import api from '@/api/';
import Mixin from '@/components/mixin/common_mixin';
import {getUserInfoByUserIdsData} from "@/utils/common";

export default {
  mixins: [Mixin],
  props: ['pickingGoodsNo', 'pickingGoodsId', 'currentPackageData','pageType','userId'],
  data() {
    let v = this;
    return {
      printBhData: [],
      original_data: [],
      row_packageCode: [],
      printedPackageColumn: [
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000966'),
          key: 'packageCodes',
          align: 'center',
          render(h,params) {
            return h('div',params.row.packageCodes.map(i => h('p',i)));
          }
        },
        {
          title: 'SKU',
          key: 'sku',
          align: 'center'
        },
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000208'),
          key: 'quantity',
          align: 'center'
        },
        /*{
          title: '库位',
          key: 'location',
          align: 'center'
        },*/
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003939'),
          key: 'printNumber',
          align: 'center',
          render (h,params) {
            return h('InputNumber', {
              props: {
                max: params.row.quantity,
                min: 0,
                value: params.row.printNumber
              },
              on: {
                input: val => {
                  v.printBhData[params.index].printNumber = val;
                },
              }
            });
          }
        },
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003940'),
          align: 'center',
          render(h,params) {
            return h('div', params.row.quantity - params.row.printNumber);
          }
        }
      ],
      columns: [
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000966'),
          key: 'packageCode'
        }, {
          title: 'SKU',
          key: 'sku'
        }, {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000208'),
          key: 'doneAssignedNumber'
        }, {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001929'),
          key: 'wmsLocationList',
          render(h, params) {
            let arr = params.row.wmsLocationList && params.row.wmsLocationList.length > 0
              ? params.row.wmsLocationList
              : [];
            return h('div', {}, arr.map(i => h('span', i)));
          }
        }, {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003939'),
          key: 'actualPickingNumber',
          render(h, params) {
            return h('InputNumber', {
              props: {
                value: v.data[params.index].actualPickingNumber,
                min: 0,
                max: v.data[params.index].doneAssignedNumber
              },
              on: {
                'on-change'(value) {
                  v.data1[params.index].actualPickingNumber = value;
                  v.data2[params.row.packageGoodsId] = value;
                  v.data[params.index].unselectedQuantity = v.data[params.index].doneAssignedNumber - v.data1[params.index].actualPickingNumber;
                }
              }
            });
          }
        }, {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003940'),
          key: 'unselectedQuantity'
        }
      ],
      data: [],
      data1: [],
      data2: {},
      isFinish: false,
      isFinishStatus: false,
      endStatus: false, // 控制展示显示正常结束信息或是异常结束信息
      countData: [// 结束作业无异常 统计项目数据
        {
          countName: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003941'),
          allPackTotalCount: null,
          packedCount: null
        }, {
          countName: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003942'),
          allPackTotalCount: null,
          packedCount: null
        }
      ],
      countBhData: [// 结束作业无异常 统计项目数据{
        {
          countName: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003942'),
          allPackTotalCount: null,
          packedCount: null
        }
      ],
      countDataColumn: [
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003943'),
          key: 'countName',
          align: 'center'
        }, {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003944'),
          key: 'allPackTotalCount',
          align: 'center'
        }, {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003945'),
          key: 'packedCount',
          align: 'center'
        }
      ],
      scannedCount: null, // 本次拣货复核人数
      scannedTime: null, // 总时间
      userScannedTime: null, // 人工时
      userData: [], // 扫描员扫描详情
      userDataColumn: [
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003946'),
          key: 'userId',
          align: 'center',
          render: (h, params) => {
            let list = this.userList;
            if (list) {
              return h('span', list[params.row.userId].userName);
            }
          }
        }, {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003947'),
          key: 'packageTotalCount',
          align: 'center'
        }, {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002866'),
          key: 'goodsTotalCount',
          align: 'center'
        }, {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003948'),
          key: 'scannedTime',
          align: 'center'
        }, {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003949'),
          key: '',
          align: 'center',
          render: (h, params) => {
            let num = params.row.packageTotalCount / params.row.scannedTime;
            return h('div', num.toFixed(2));
          }
        }, {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003950'),
          key: '',
          align: 'center',
          render: (h, params) => {
            let num = params.row.goodsTotalCount / params.row.scannedTime;
            return h('div', num.toFixed(2));
          }
        }
      ],
      userDataBhColumn: [
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003946'),
          key: 'userId',
          align: 'center',
          render: (h, params) => {
            return h('span', v.userId);
          }
        },
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002866'),
          key: 'goodsTotalCount',
          align: 'center'
        },
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003948'),
          key: 'scannedTime',
          align: 'center',
          render (h) {
            let time = v.scannedTime || 0;
            return h('span',time);
          }
        }
      ],
      userBhData: [],
      printChioseData: [], // 已打印包裹code
      unPrintChioseData: [], // 未打印包裹code
      rowChioseNum: 1,
      headChioseNum: 1,
      printData: [], // 结束作业有异常 正在打印中的数据
      printDataColumn: [
        {
          title: 'NO',
          width: 60,
          align: 'center',
          fixed: 'left',
          type: 'index',
        }, {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000966'),
          key: 'packageCode',
          align: 'center'
        }, {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002920'),
          key: 'trackingNumber',
          align: 'center'
        }, {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003951'),
          key: 'buyerName',
          align: 'center'
        }, {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003952'),
          key: 'scannedTime',
          align: 'center',
          render: (h, params) => {
            let date = this.$uDate.dealTime(params.row.scannedTime);
            return h('div', date);
          }
        }, {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000283'),
          width: 150,
          align: 'center',
          renderHeader: (h, params) => {
            return h('RadioGroup', {
              props: {
                value: this.headChioseNum
              },
              on: {
                'on-change': value => {
                  this.allChiose(value);
                }
              }
            }, [
              h('Radio', {
                style: {
                  color: '#378000'
                },
                props: {
                  label: 1
                }
              }, aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000626')), h('Radio', {
                style: {
                  color: '#ff0000'
                },
                props: {
                  label: 0
                }
              }, aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000626'))
            ]);
          },
          render: (h, params) => {
            return h('RadioGroup', {
              props: {
                value: this.rowChioseNum
              },
              on: {
                'on-change': value => {
                  this.rowChiose(value, params.index);
                }
              }
            }, [
              h('Radio', {
                style: {
                  color: '#378000'
                },
                props: {
                  label: 1
                }
              }, aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003953')), h('Radio', {
                style: {
                  color: '#ff0000'
                },
                props: {
                  label: 0
                }
              }, aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003954'))
            ]);
          }
        }
      ],
      printGoodsData: [], // 正在打印中的货品小计
      printGoodsDataColumn: [
        {
          title: 'NO',
          width: 60,
          align: 'center',
          fixed: 'left',
          type: 'index',
        }, {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003955'),
          key: 'sku',
          align: 'center'
        }, {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000447'),
          key: 'quantity',
          align: 'center'
        }
      ],
      cancelData: [], // 打印前作废的包裹信息
      cancelDataColumn: [
        {
          title: 'NO',
          width: 60,
          align: 'center',
          fixed: 'left',
          type: 'index',
        }, {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000966'),
          key: 'packageCode',
          align: 'center'
        }, {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002920'),
          key: 'trackingNumber',
          align: 'center'
        }, {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003951'),
          key: 'buyerName',
          align: 'center'
        }, {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003629'),
          key: '',
          align: 'center',
          render: (h, params) => {
            let pos = [];
            if (this.cancelGoodsData) {
              this.cancelGoodsData.forEach((n, i) => {
                if (n.packageCode === params.row.packageCode) {
                  let item = h('div', [h('div', n.sku + ' * ' + n.quantity)]);
                  pos.push(item);
                }
              });
              return h('div', pos);
            }
          }
        }
      ],
      cancelGoodsData: [], // 打印前作废sku
      cancelGoodsDataColumn: [
        {
          title: 'NO',
          width: 60,
          align: 'center',
          fixed: 'left',
          type: 'index',
        }, {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003955'),
          key: 'sku',
          align: 'center'
        }, {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000447'),
          key: 'quantity',
          align: 'center'
        }
      ],
      cancelPrintData: [], // 打印后作废的包裹信息
      cancelPrintColumn: [
        {
          title: 'NO',
          width: 60,
          align: 'center',
          fixed: 'left',
          type: 'index',
        }, {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000966'),
          key: 'packageCode',
          align: 'center'
        }, {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002920'),
          key: 'trackingNumber',
          align: 'center'
        }, {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003951'),
          key: 'buyerName',
          align: 'center'
        }, {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003629'),
          key: '',
          align: 'center',
          render: (h, params) => {
            let pos = [];
            if (this.cancelPrintData) {
              this.cancelPrintData.forEach((n, i) => {
                if (n.packageCode === params.row.packageCode) {
                  let item = h('div', [h('div', n.sku + ' * ' + n.quantity)]);
                  pos.push(item);
                }
              });
              return h('div', pos);
            }
          }
        }
      ],
      cancelPrintGoodsData: [], // 打印后作废的sku
      cancelPrintGoodsColumn: [
        {
          title: 'NO',
          width: 60,
          align: 'center',
          fixed: 'left',
          type: 'index',
        }, {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003955'),
          key: 'sku',
          align: 'center'
        }, {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000447'),
          key: 'quantity',
          align: 'center'
        }
      ],
      userList: [] // 作业人
    };
  },
  methods: {
    PrintDistributionList() {
      /**
       * 打印配货清单
       * */
      let v = this;
      let obj = {
        packageCodeList: v.data.map(i => i.packageCode),
        warehouseId: v.getWarehouseId()
      };
      v.$Spin.show();
      v.axios.post(api.post_packageInfo_queryDeliveryListPath, obj).then(response => {
        v.$Spin.hide();
        if (response.data.code === 0) {
          let data = response.data.datas;
          data.every((n, i) => {
            v.commonPrint(n.deliveryListLabelPath, n.packageCode);
            return true;
          });
        }
      });
    },
    normalData() {
      // 获取正常的结束展示信息
      let v = this; // countData
      let pos = [];
      v.axios.get(api.get_wmsPickingGoods_statisticsPacking + v.pickingGoodsId).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          // console.log(data);
          v.countData[0].allPackTotalCount = data.packageTotalCount; // 拣货单所有包裹数量
          v.countData[0].packedCount = data.packedPackageCount; // 拣货单已完成拣货复核包裹数量
          v.countData[1].allPackTotalCount = data.goodsTotalCount; // 拣货单所有货品总数
          v.countData[1].packedCount = data.packedGoodsCount; // 拣货单完成扫描货品数量
          v.countBhData[0].allPackTotalCount = data.goodsTotalCount;
          v.countBhData[0].packedCount = data.goodsTotalCount;
          v.userBhData = [];
          v.userBhData.push({
            goodsTotalCount :data.goodsTotalCount
          });
          v.scannedCount = data.scannedCount; // 本次拣货复核人数
          v.scannedTime = data.scannedTime; // 包装总时间
          v.userScannedTime = data.userScannedTime; // 人工时
          data.scannerDetails.forEach((n, i) => {
            pos.push(n.userId);
          });
          if (pos.length > 0) {
            getUserInfoByUserIdsData(pos,v).then((userData)=> {
              v.userList = userData;
              v.userData = data.scannerDetails; // 扫描员扫描详情
            });
          }
        }
      });
    },
    abnormalData() {
      // 获取异常的结束展示信息
      let v = this;
      v.row_packageCode = [];
      v.axios.get(api.get_wmsPickingGoods_getPackingWarnPackages + v.pickingGoodsId).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          v.printData = data.printingPackages; // 打印中的包裹信息
          v.printGoodsData = data.printingSkus; // 打印中的sku
          v.cancelData = data.unprintPackages; // 作废的的包裹信息
          v.cancelGoodsData = data.unprintSkus; // 作废的的包裹sku
          v.cancelPrintData = data.invalidPackages; // 打印后作废的包裹
          v.cancelPrintGoodsData = data.invalidSkus; // 打印后作废的sku
          // currentPackageData
          v.data = [];
          v.$nextTick(()=>{
            v.data = data.waitPrintSuccessSkus.map(i => {
              i.unselectedQuantity = i.doneAssignedNumber;
              i.actualPickingNumber = 0;
              // v.currentPackageData.forEach(j => {
              //   if (i.sku === j.sku) {
              //     i.unselectedQuantity = i.doneAssignedNumber - j.scanQuantity;
              //   }
              // });
              return i;
            }).filter(i => i.unselectedQuantity > 0); // 未拣货/打印出库单明细
          })
          v.original_data = v.data;
          v.data1 = data.waitPrintSuccessSkus.map(i => {
            return {
              packageGoodsId: i.packageGoodsId,
              actualPickingNumber: 0
            };
          }); // 未拣货/打印出库单明细
          let new_data = [];
          if (v.headChioseNum === 1) {
            let packageCode_arr = [];
            v.printData.map((ele) => {
              packageCode_arr.push(ele.packageCode);
            });

            v.data.map((item) => {
              if (packageCode_arr.indexOf(item.packageCode) <= -1) {
                new_data.push(item);
              }
            });
          }
          v.data = new_data;
        }
      });
    },
    changeChiose(value) {
      let v = this;
      v.cancelData.forEach((i, index) => {
        v.$set(v.cancelData[index], 'rowChioseNum', value);
      });
      this.rowChioseNum = value;
    },
    allChiose(val) {
      let v = this;
      // 先清空，防止点全选前点过单个
      v.printChioseData = [];
      v.unPrintChioseData = [];
      v.headChioseNum = val;
      if (val) {
        // 0未打 1已打
        v.rowChioseNum = 1;
        let pos = [];
        v.printData.forEach((n, i) => {
          pos.push(n.packageCode);
        });
        v.printChioseData = pos;

        let new_data = [];
        if (v.headChioseNum === 1) {
          let packageCode_arr = [];
          v.printData.map((ele) => {
            packageCode_arr.push(ele.packageCode);
          });

          v.original_data.map((item) => {
            if (packageCode_arr.indexOf(item.packageCode) <= -1) {
              new_data.push(item);
            }
          });
        }
        v.data2 = {};
        v.data = new_data;
      } else {
        v.rowChioseNum = 0;
        let box = [];
        v.printData.forEach((n, i) => {
          box.push(n.packageCode);
        });
        v.unPrintChioseData = box;
        v.data = v.original_data;
      }
    },
    rowChiose(val, index) {
      let v = this;
      // 判断是否点过全选，若有则需改值和状态
      if (v.headChioseNum !== null) {
        if (val !== v.headChioseNum) {
          v.headChioseNum = null;
        } else {
          return false;
        }
      }
      if (val) {
        // 0未打 1已打
        v.printChioseData.push(v.printData[index].packageCode);
        // 若当前code为已打，则需判断未打中是否有这个code，有则移除（改变状态时用到）
        if (v.unPrintChioseData.indexOf(v.printData[index].packageCode) !== -1) {
          let pid = v.unPrintChioseData.indexOf(v.printData[index].packageCode);
          v.unPrintChioseData.splice(pid, 1);
        }

        let new_data = [];
        v.row_packageCode.push(v.printData[index].packageCode);
        v.original_data.map((item) => {
          if (v.row_packageCode.indexOf(item.packageCode) <= -1) {
            new_data.push(item);
          }
        });
        v.data2 = {};
        v.data = new_data;
      } else {
        v.original_data.map((item) => {
          if (item.packageCode === v.printData[index].packageCode) {
            v.data.push(item);
            v.row_packageCode.splice(index, 1);
          }
        });
        v.unPrintChioseData.push(v.printData[index].packageCode);
        // 若当前code为未打，则需判断已打中是否有这个code，有则移除（改变状态时用到）
        if (v.printChioseData.indexOf(v.printData[index].packageCode) !== -1) {
          let uid = v.printChioseData.indexOf(v.printData[index].packageCode);
          v.printChioseData.splice(uid, 1);
        }
      }
      if (v.printChioseData.length === v.printData.length) {
        v.headChioseNum = 1;
      } else if (v.unPrintChioseData.length === v.printData.length) {
        v.headChioseNum = 0;
      }
    },
    finishPackWork(type) {
      // 结束作业
      let v = this;
      let obj;
      if (v.headChioseNum === 1) {
        let pos = [];
        v.printData.forEach((n, i) => {
          pos.push(n.packageCode);
        });
        v.printChioseData = pos;
      }
      if (v.pageType === 'prepareGoods') {
        let params = {
          "pickingGoodsId": v.pickingGoodsId,
          "stockOrderActualPickingNumBos": []
        };
        if (v.endStatus === true) {
          params.stockOrderActualPickingNumBos = v.printBhData.map(i => {
            return {
              "actualPickingNumber": i.quantity,
              "productGoodsId": i.productGoodsId
            };
          });
        } else {
          params.stockOrderActualPickingNumBos = v.printBhData.map(i => {
            return {
              "actualPickingNumber": i.printNumber,
              "productGoodsId": i.productGoodsId
            };
          });
        }
        console.log(params);
        v.axios.put(api.put_wmsPickingGoods_confirmFinishStockOrderPacking,params).then(response=> {
          if (response.data.code === 0) {
            v.$router.push('/wms/packWorking');
          }
        });
      } else {
        if (type) {
          // 有异常 强制结束
          let new_arr = [];
          if (v.data) {
            v.data.map((item) => {
              new_arr.push({
                packageGoodsId: item.packageGoodsId,
                actualPickingNumber: v.data2[item.packageGoodsId] || 0
              });
            });
          }
          obj = {
            pickingGoodsId: v.pickingGoodsId,
            printPackageCodes: v.printChioseData,
            unprintPackageCodes: v.unPrintChioseData,
            updateActualPickingNumBoList: new_arr
            // updateActualPickingNumBoList: v.data1
          };
        } else {
          // 无异常
          obj = {
            pickingGoodsId: v.pickingGoodsId,
            printPackageCodes: [],
            unprintPackageCodes: []
          };
        }
        if (v.data.length > 0) {
          let new_arr = v.handerGrouping(v.data, function (item) {
            return [item.packageCode]; // 可添加多个属性
          }, 'packageCode');
          let unselectedQuantity_arr = [];
          if (new_arr.length > 0) {
            new_arr.map((ele) => {
              if (ele.data.length > 0) {
                ele.data.map((talg) => {
                  if (talg.unselectedQuantity > 0) {
                    unselectedQuantity_arr.push(talg);
                  }
                });
              }
            });
          }
          if (unselectedQuantity_arr.length <= 0) {
            v.$Message.error({
              content: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006138'),
              duration: 4
            });
            return false;
          }
        }
        v.axios.put(api.put_wmsPickingGoods_confirmFinishPacking, obj).then(response => {
          if (response.data.code === 0) {
            v.$router.push('/wms/packWorking');
            // window.location.href = '//' + window.location.host + '/wms-service/wms.html#/packWorking';
            v.printChioseData = [];
            v.unPrintChioseData = [];
          }
        });
      }

    },
    backPacking() {
      // 返回继续包装
      let v = this;
      v.isFinish = false;
      v.headChioseNum = 1;
      v.rowChioseNum = 1;
      v.printChioseData = [];
      v.unPrintChioseData = [];
    },
    importExcel(val) {
      // 正在打印 导出
      let v = this;
      let obj = {};
      let filenodeViewTargetUrl = localStorage.getItem('imgUrlPrefix');
      if (val) {
        // printGoodsData
        if (!v.printGoodsData) {
          v.$Message.error('no data！');
          return false;
        }
        // 组装数据
        let excelContentList = []; // 数据
        let excelTitleList = [// 表头
          {
            title: 'No',
            column: 'no'
          }, {
            title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003955'),
            column: 'goodsTotal'
          }, {
            title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000447'),
            column: 'goodsNum'
          }
        ];
        let pos = [];
        v.printGoodsData.forEach((n, i) => {
          pos.push([
            {
              column: 'no',
              content: i + 1
            }, {
              column: 'goodsTotal',
              content: n.sku
            }, {
              column: 'goodsNum',
              content: n.quantity
            }
          ]);
        });
        pos.forEach((m, t) => {
          excelContentList.push({
            excelColumnContentList: m
          });
        });
        obj = {
          excelContentList: excelContentList,
          excelTitleList: excelTitleList
        };
        let newTab = window.open('about:blank');
        v.axios.post(api.post_packageGoods_exportPackingPrintingSkusExcel, obj).then(response => {
          if (response.data.code === 0) {
            // let newTab = window.open('about:blank');
            newTab.location.href = filenodeViewTargetUrl + response.data.datas;
            setTimeout(function () {
              newTab.close();
            }, 1000);
          } else {
            newTab.close();
          }
        });
      } else {
        // printData
        if (!v.printData.length) {
          v.$Message.error('no data！');
          return false;
        }
        // 组装数据
        let excelContentList = []; // 数据
        let excelTitleList = [// 表头
          {
            title: 'No',
            column: 'no'
          }, {
            title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000132'),
            column: 'pickNo'
          }, {
            title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002920'),
            column: 'followNo'
          }, {
            title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003951'),
            column: 'buyerName'
          }, {
            title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003952'),
            column: 'time'
          }
        ];
        let pos = [];
        // let box = [];
        v.printData.forEach((n, i) => {
          let date = this.$uDate.dealTime(n.scannedTime);
          pos.push([
            {
              column: 'no',
              content: i + 1
            }, {
              column: 'pickNo',
              content: n.packageCode
            }, {
              column: 'followNo',
              content: n.trackingNumber
            }, {
              column: 'buyerName',
              content: n.buyerName
            }, {
              column: 'time',
              content: date
            }
          ]);
        });
        pos.forEach((m, t) => {
          excelContentList.push({
            excelColumnContentList: m
          });
        });
        obj = {
          excelContentList: excelContentList,
          excelTitleList: excelTitleList
        };
        let newTab = window.open('about:blank');
        v.axios.post(api.post_packageInfo_exportPackingPrintingExcel, obj).then(response => {
          if (response.data.code === 0) {
            // let newTab = window.open('about:blank');
            newTab.location.href = filenodeViewTargetUrl + response.data.datas;
            setTimeout(function () {
              newTab.close();
            }, 1000);
          } else {
            newTab.close();
          }
        });
      }
    },
    startImportExcel(val) {
      // 打印前作废 导出
      let v = this;
      let obj = {};
      let filenodeViewTargetUrl = localStorage.getItem('imgUrlPrefix');
      if (val) {
        // cancelGoodsData
        if (!v.cancelGoodsData) {
          v.$Message.error('no data！');
          return false;
        }
        // 组装数据
        let excelContentList = []; // 数据
        let excelTitleList = [// 表头
          {
            title: 'No',
            column: 'no'
          }, {
            title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003955'),
            column: 'goodsTotal'
          }, {
            title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000447'),
            column: 'goodsNum'
          }
        ];
        let pos = [];
        v.cancelGoodsData.forEach((n, i) => {
          pos.push([
            {
              column: 'no',
              content: i + 1
            }, {
              column: 'goodsTotal',
              content: n.sku
            }, {
              column: 'goodsNum',
              content: n.quantity
            }
          ]);
        });
        pos.forEach((m, t) => {
          excelContentList.push({
            excelColumnContentList: m
          });
        });
        obj = {
          excelContentList: excelContentList,
          excelTitleList: excelTitleList
        };
        let newTab = window.open('about:blank');
        v.axios.post(api.post_packageGoods_exportPackingUnPrintSkusExcel, obj).then(response => {
          if (response.data.code === 0) {
            // let newTab = window.open('about:blank');
            newTab.location.href = filenodeViewTargetUrl + response.data.datas;
            setTimeout(function () {
              newTab.close();
            }, 1000);
          } else {
            newTab.close();
          }
        });
      } else {
        // cancelData
        if (!v.cancelData.length) {
          v.$Message.error('no data！');
          return false;
        }
        // 组装数据
        let excelContentList = []; // 数据
        let excelTitleList = [// 表头
          {
            title: 'No',
            column: 'no'
          }, {
            title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000132'),
            column: 'pickNo'
          }, {
            title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002920'),
            column: 'followNo'
          }, {
            title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003951'),
            column: 'buyerName'
          }, {
            title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003629'),
            column: 'goodsMes'
          }
        ];
        let pos = [];
        let box = [];
        v.cancelData.forEach((n, i) => {
          if (v.cancelGoodsData) {
            v.cancelGoodsData.forEach((v, p) => {
              if (v.packageCode === n.packageCode) {
                let item = v.sku + ' * ' + v.quantity;
                box.push(item);
              }
            });
          }
          pos.push([
            {
              column: 'no',
              content: i + 1
            }, {
              column: 'pickNo',
              content: n.packageCode
            }, {
              column: 'followNo',
              content: n.trackingNumber
            }, {
              column: 'buyerName',
              content: n.buyerName
            }, {
              column: 'goodsMes',
              content: box.toString()
            }
          ]);
        });
        pos.forEach((m, t) => {
          excelContentList.push({
            excelColumnContentList: m
          });
        });
        obj = {
          excelContentList: excelContentList,
          excelTitleList: excelTitleList
        };
        let newTab = window.open('about:blank');
        v.axios.post(api.post_packageInfo_exportPackingUnPrintExcel, obj).then(response => {
          if (response.data.code === 0) {
            // let newTab = window.open('about:blank');
            newTab.location.href = filenodeViewTargetUrl + response.data.datas;
            setTimeout(function () {
              newTab.close();
            }, 1000);
          } else {
            newTab.close();
          }
        });
      }
    },
    endImportExcel(val) {
      // 打印后作废 导出
      let v = this;
      let obj = {};
      let filenodeViewTargetUrl = localStorage.getItem('imgUrlPrefix');
      if (val) {
        // cancelPrintGoodsData
        if (!v.cancelPrintGoodsData) {
          v.$Message.error('no data！');
          return false;
        }
        // 组装数据
        let excelContentList = []; // 数据
        let excelTitleList = [// 表头
          {
            title: 'No',
            column: 'no'
          }, {
            title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003955'),
            column: 'goodsTotal'
          }, {
            title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000447'),
            column: 'goodsNum'
          }
        ];
        let pos = [];
        v.cancelPrintGoodsData.forEach((n, i) => {
          pos.push([
            {
              column: 'no',
              content: i + 1
            }, {
              column: 'goodsTotal',
              content: n.sku
            }, {
              column: 'goodsNum',
              content: n.quantity
            }
          ]);
        });
        pos.forEach((m, t) => {
          excelContentList.push({
            excelColumnContentList: m
          });
        });
        obj = {
          excelContentList: excelContentList,
          excelTitleList: excelTitleList
        };
        let newTab = window.open('about:blank');
        v.axios.post(api.post_packageGoods_exportPackingInvalidSkusExcel, obj).then(response => {
          if (response.data.code === 0) {
            // let newTab = window.open('about:blank');
            newTab.location.href = filenodeViewTargetUrl + response.data.datas;
            setTimeout(function () {
              newTab.close();
            }, 1000);
          } else {
            newTab.close();
          }
        });
      } else {
        // cancelPrintData
        if (!v.cancelPrintData.length) {
          v.$Message.error('no data！');
          return false;
        }
        // 组装数据
        let excelContentList = []; // 数据
        let excelTitleList = [// 表头
          {
            title: 'No',
            column: 'no'
          }, {
            title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000132'),
            column: 'pickNo'
          }, {
            title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002920'),
            column: 'followNo'
          }, {
            title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003951'),
            column: 'buyerName'
          }, {
            title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003629'),
            column: 'goodsMes'
          }
        ];
        let pos = [];
        let box = [];
        v.cancelPrintData.forEach((n, i) => {
          if (v.cancelGoodsData) {
            v.cancelGoodsData.forEach((v, p) => {
              if (v.packageCode === n.packageCode) {
                let item = v.sku + ' * ' + v.quantity;
                box.push(item);
              }
            });
          }
          pos.push([
            {
              column: 'no',
              content: i + 1
            }, {
              column: 'pickNo',
              content: n.packageCode
            }, {
              column: 'followNo',
              content: n.trackingNumber
            }, {
              column: 'buyerName',
              content: n.buyerName
            }, {
              column: 'goodsMes',
              content: box.toString()
            }
          ]);
        });
        pos.forEach((m, t) => {
          excelContentList.push({
            excelColumnContentList: m
          });
        });
        obj = {
          excelContentList: excelContentList,
          excelTitleList: excelTitleList
        };
        let newTab = window.open('about:blank');
        v.axios.post(api.post_packageInfo_exportPackingInvalidExcel, obj).then(response => {
          if (response.data.code === 0) {
            // let newTab = window.open('about:blank');
            newTab.location.href = filenodeViewTargetUrl + response.data.datas;
            setTimeout(function () {
              newTab.close();
            }, 1000);
          } else {
            newTab.close();
          }
        });
      }
    }
  },
  watch: {
    printData: {
      handler(data) {
        console.log('++++++===========', data);
      },
      immediate: true,
      deep: true
    },
    currentPackageData: {
      handler(data) {
        this.printBhData = JSON.parse(JSON.stringify(data));
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style scoped>
.title {
  padding: 20px 0;
}

.red {
  color: red;
}

.ml10 {
  margin-left: 10px;
}
</style>
